import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

/**
 * Api is a generic REST Api handler. Set your API url first.
 */
@Injectable()
export class Api {
   //url: string = 'http://101.53.134.119:8080';//old live
   
   //url: string = 'https://apisagartenament.jmtechsoft.com/api';
   url: string = 'https://api.jmgate.in/api'; 
    
  constructor(public http: HttpClient) {
  }
//   makeRequest() {
//     // Get the token from localStorage or any other source
//     const token = localStorage.getItem('token');
  
//     // Set the token in the request headers
//     const headers = new HttpHeaders({
//       Authorization: `Bearer ${token}`
//     });
  
//     // Make the HTTP request with the headers
//     this.http.get('https://example.com/api/endpoint', { headers }).subscribe(
//       response => {
//         console.log(response);
//       },
//       error => {
//         console.error(error);
//       }
//     );
//   }

  get(endpoint: string, params?: any, reqOpts?: any) {
    if (!reqOpts) {
      reqOpts = {
        params: new HttpParams()
      };
    }

    // Support easy query params for GET requests
    if (params) {
      reqOpts.params = new HttpParams();
      for (let k in params) {
        reqOpts.params = reqOpts.params.set(k, params[k]);
      }
    }

    return this.http.get(this.url + '/' + endpoint, reqOpts);
  }

  getsendparam(endpoint: string, params?: any,reqOpts?: any) {
    
 
    return this.http.get(this.url + '/' + endpoint+'/'+params);

   
  }
  getpostmethod(endpoint: string,body:any, params?: any, reqOpts?: any){
  
      if (!reqOpts) {
        reqOpts = {
          params: new HttpParams()
        };
      }
  
      // Support easy query params for GET requests
      if (params) {
        reqOpts.params = new HttpParams();
        for (let k in params) {
          reqOpts.params = reqOpts.params.set(k, params[k]);
        }
      }
  
      return this.http.post(this.url + '/' + endpoint,body, reqOpts);
  }

  post(endpoint: string, body: any, reqOpts?: any) {
    return this.http.post(this.url + '/' + endpoint, body, reqOpts);
  }

  put(endpoint: string, body: any, reqOpts?: any) {
    return this.http.put(this.url + '/' + endpoint, body, reqOpts);
  }

  delete(endpoint: string, reqOpts?: any) {
    return this.http.delete(this.url + '/' + endpoint, reqOpts);
  }

  patch(endpoint: string, body: any, reqOpts?: any) {
    return this.http.patch(this.url + '/' + endpoint, body, reqOpts);
  }
}
