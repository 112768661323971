import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // Get the token from localStorage or any other source
    const token = localStorage.getItem('token');

    // Clone the request and add the token to the headers
    const authReq = req.clone({
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`
      })
    });

    // Pass the cloned request to the next handler
    return next.handle(authReq);
  }
}