
import { NavigationExtras, Router } from '@angular/router';
import { AlertController, NavController, Platform } from '@ionic/angular';
import { Component } from '@angular/core';
import { EventService } from './services/event.service';
import { TranslateService } from '@ngx-translate/core';
import { App as CapacitorApp } from '@capacitor/app';
import OneSignal from 'onesignal-cordova-plugin';

import { Location } from '@angular/common';
import { Api } from './services/api';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  public appPages = [
    { title: 'Dealr Solutions', url: '/folder/Inbox', icon: 'mail' },
    { title: 'Car Loan', url: '/folder/Outbox', icon: 'paper-plane' },
    { title: 'EMI Calculator', url: '/folder/Favorites', icon: 'heart' },
    { title: 'Car Insurance', url: '/folder/Archived', icon: 'archive' },
  ];

  public labels = ['Dealr Solutions', 'Car Loan', 'EMI Calculator', 'Car Insurance'];
  logininfo: any;
  desid: any;

  constructor(private router: Router, public events: EventService, private translate: TranslateService, private platform: Platform, private alertController: AlertController, private _location: Location, public masterapi: Api) {
    this.events.getObservable().subscribe((data) => {
      console.log("Data received:", data);
      if (data.isLogin == true) {
        let logindata = localStorage.getItem('logininfo');
        this.logininfo = JSON.parse(logindata);
        this.desid = this.logininfo.designation


      }


    })
    this.initializeApp();
    let lang = localStorage.getItem('language');
    debugger

    this.translate.setDefaultLang(lang != 'null' ? lang : 'en');
    this.platform.backButton.subscribeWithPriority(9999, () => {
      console.log('Handler was called!--22');
      let page = this.platform.url().split("/").pop()
      // alert(page)
      debugger

      if (page == 'home' || page == 'login') {
        // CapacitorApp.exitApp();
        this.showConfirmationDialog().then((confirmed) => {
          if (confirmed) {
            CapacitorApp.exitApp();
          }
        })
      } else {
        //alert('Back button pressed!');
        this._location.back();
      }



    });
  }

  checkpermission(name) {
    let logindata = localStorage.getItem('logininfo');
    this.logininfo = JSON.parse(logindata);
    if (this.logininfo.rights.length > 0) {
      let foundItem = this.logininfo.rights.find(item => item.module === name);
      if (foundItem) {
        return foundItem.isDisplay ? true : false;
        // console.log(`The object with name '${name}' was found:`, foundItem);
      }
    }
    //Society Member


  }
  async showConfirmationDialog(): Promise<boolean> {

    return new Promise<boolean>((resolve) => {
      this.alertController
        .create({
          header: 'Confirmation',
          message: 'Are you sure you want to close the app?',
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              handler: () => {

                resolve(false); // User chose to cancel closing the app
              },
            },
            {
              text: 'Close',
              handler: () => {
                resolve(true); // User confirmed closing the app
              },
            },
          ],
        })
        .then((alert) => {
          alert.present();
        });
    });
  }
  initializeApp() {
    ;
    this.platform.ready().then(() => {
      if (this.platform.is('hybrid')) {
        this.OneSignalInit()
       // this.getplayerid()
      }
    });
  }

  OneSignalInit() {

    // Uncomment to set OneSignal device logging to VERBOSE  
    // OneSignal.setLogLevel(6, 0);
    debugger
    let playerId;
    // NOTE: Update the setAppId value below with your OneSignal AppId.
    OneSignal.setAppId("5f98c977-33f2-4d7a-932f-4f7fd265890f");

    // OneSignal.setNotificationOpenedHandler(function (jsonData) {
    //   debugger
    //   console.log('jsonData info=>', jsonData);
    //   this.oneSignal.getIds().then((data) => {
    //     playerId = data.userId;
    //     console.log('OneSignal Player ID:', playerId);
    //     console.log('user info=>', data);
    //     this.insertuuid(data)
    //   })
    //   console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
    // });

    // Prompts the user for notification permissions.
    //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 7) to better communicate to your users what notifications they will get.
    // OneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {
    //   console.log("User accepted notifications: " + accepted);
    //   this.oneSignal.getIds().then((data) => {
    //     console.log('user info=>', data);
    //   })
    //   debugger
    // });



  }
  // getplayerid() {
  //   var appId = '5f98c977-33f2-4d7a-932f-4f7fd265890f';
  //   var restApiKey = 'NGNlYjc3ZGQtYmI2ZS00NjFkLTg1YzYtNTJjMmU0YTIzMDI0';
  //   var deviceId = 'c2cd2341d86299ee';
  //   fetch(`https://onesignal.com/api/v1/players?app_id=${appId}&identifier=${deviceId}`, {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Authorization': `Basic ${restApiKey}`
  //     }
  //   })
  //     .then(response => response.json())
  //     .then(data => {
  //       if (data.players.length > 0) {
  //         var playerId = data.players[0].id;
  //        // alert(playerId);
  //         console.log('Player ID:', playerId);
  //       } else {
  //         console.log('Player ID not found for the device ID:', deviceId);
  //       }
  //     })
  //     .catch(error => {
  //       console.error('Error:', error);
  //     });
  // }



  // goToNewCars(val) {
  //   const navData: NavigationExtras = {
  //     queryParams: {
  //       id: JSON.stringify(val)
  //     }
  //   };
  //   this.router.navigate(['/new-cars'], navData);
  // }



  goToTest() {
    this.router.navigate(['/test']);
  }

  insertuuid(item) {
    let data =
    {
      'isPlayerId': true,
      'onesignal_player_id': item.userId
    }

    if (item.androidSDKVersion) {
      this.masterapi.post('insert_update_device_id', data).subscribe(
        async (resp) => {
          console.log('insert_update_device_id', resp);

          if (resp) {
            //  this.topsearchdata = resp['data']
            //hotels_result
            //locality_master_result 


            //this.dismissLoader()
          }
        },
        // async (err) => {
        //   const toast = await this.toastCtrl.create({
        //     message: "Please try again later",
        //     duration: 2000,
        //     cssClass: 'toast-background-error'
        //   });
        //   toast.present();
        //   // this.dismissLoader()
        // }
      );
    }

  }

  //
  goToPage(url) {
    this.router.navigate([url]);
  }

  Logout() {
    localStorage.clear();
    let lang = localStorage.getItem('language');

    localStorage.setItem('language', lang);
    this.router.navigate(['/login']);
  }


}
