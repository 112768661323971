import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})

export class SearchPipe implements PipeTransform {
  transform(items: any[], searchTerm: string, keys: string[]): any[] {
    if (!items || !searchTerm) {
      return items;
    }

    searchTerm = searchTerm.toLowerCase();

    return items.filter(item => {
      return keys.some(key => {
        const value = item[key];
        return value && value.toLowerCase().includes(searchTerm);
      });
    });
  }
}